import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

const variants = {
    blue: {
        infoIcon: "bg-blue-400",
        cornerIcon: "bg-blue-400",
        icon: "bg-blue-400",
        button: "bg-blue-500",
        description: "bg-blue-100",
    },
    red: {
        infoIcon: "bg-red-400",
        cornerIcon: "bg-red-400",
        icon: "bg-red-400",
        button: "bg-red-500",
        description: "bg-red-100",
    },
    green: {
        infoIcon: "bg-green-400",
        cornerIcon: "bg-green-400",
        icon: "bg-green-400",
        button: "bg-green-500",
        description: "bg-green-100",
    },
};

/**
 * @callback onClick
 * @param {React.MouseEventHandler<HTMLButtonElement>} event
 * @returns {void}
 */
/**
 * @typedef {Object} OptionCardProps
 * @property {string} title
 * @property {string} subtitle
 * @property {string} description
 * @property {onClick} onClick
 * @property {IconDefinition} [icon]
 * @property {IconDefinition} [cornerIcon]
 * @property {string} [iconAlternativeText]
 * @property {string} [buttonText]
 * @property {string} [variant]
 */

/**
 * @param {OptionCardProps} props
 * @returns {JSX.Element}
 */

function OptionCard(props) {
    const { title, subtitle, description, onClick, icon, cornerIcon, iconAlternativeText, buttonText, className } = props;
    console.log(props.variant)
    const variant = variants[props.variant];

    const [openInfo, setOpenInfo] = useState(false);

    const handleButtonClick = (e) => {
        e.stopPropagation();
        onClick(e);
    };

    return (
        <div className={"relative mx-16 my-6 min-h-[360px] w-72" + (className ? className : "")}>
            <div
                className={`${
                    openInfo && "-translate-y-44"
                } min-h-[360px] py-8 z-10 relative flex flex-col justify-between shadow-xl transition-all ease-in-out hover:shadow-2xl hover:scale-105 duration-500 items-center rounded-lg bg-white p-12`}
                onClick={() => openInfo && setOpenInfo(!openInfo)}
            >
                <div
                    className={`cursor-pointer ${variant.infoIcon} h-8 w-8 p-1 flex items-center justify-center rounded-full text-white absolute z-10 top-2 right-2`}
                    onClick={() => setOpenInfo(!openInfo)}
                >
                    <FontAwesomeIcon icon={faInfo} fontSize="16px" />
                </div>
                <div className="relative flex flex-col items-center mb-8 z-10">
                    <div className={`${variant.icon} rounded-full h-36 w-36 flex items-center justify-center`}>
                        {iconAlternativeText ? (
                            <p style={{ fontFamily: "Poppins" }} className="text-5xl font-bold text-white">
                                {iconAlternativeText}
                            </p>
                        ) : (
                            <FontAwesomeIcon color="white" icon={icon} fontSize="64px" />
                        )}
                    </div>
                    {cornerIcon && (
                        <div
                            className={`absolute top-0 right-0 ring-4 ring-white ${variant.cornerIcon} rounded-full h-12 w-12 flex items-center justify-center`}
                        >
                            <FontAwesomeIcon color="white" icon={cornerIcon} fontSize="24px" />
                        </div>
                    )}
                </div>

                <div className="text-center">
                    <h3 style={{ fontFamily: "Poppins" }}>{title}</h3>
                    <p style={{ fontFamily: "Poppins" }} className="text-slate-500">
                        {subtitle}
                    </p>
                    <button
                        onClick={handleButtonClick}
                        style={{ fontFamily: "Poppins" }}
                        className={`mt-4 rounded-full ${variant.button} px-4 py-1 text-white`}
                    >
                        {buttonText || "Oyna"}
                    </button>
                </div>
            </div>
            <div className={`flex items-center justify-center rounded-lg h-44 absolute z-0 bottom-0 ${variant.description} px-6 w-full`}>
                <p style={{ fontFamily: "Poppins" }}>{description}</p>
            </div>
        </div>
    );
}

export default OptionCard;
