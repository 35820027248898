import { Tooltip } from "@mui/material";
import Leaderboard from "./Leaderboard";

function LeaderboardTab(props) {
  const { title, tooltipText, gamemode } = props;
  console.log(props.variant);

  return (
    <div>
      <strong class="flex justify-center my-2">
        {title}
        <Tooltip title={tooltipText} arrow>
          <button type="button">
            <svg
              class="ml-2 w-4 h-4 text-gray-400 hover:text-gray-500"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <span class="sr-only">Show information</span>
          </button>
        </Tooltip>
      </strong>
      <div>
        <Leaderboard gamemode={gamemode} />
      </div>
    </div>
  );
}

export default LeaderboardTab;
