const baseButtonStyle = "shadow-lg px-6 py-3.5 uppercase font-medium text-sm rounded-2xl leading-tight disabled:opacity-50 ";

const buttonStyles = {
    correct: baseButtonStyle + "bg-green-600 border-green-500 hover:bg-green-600 text-white active:bg-green-700",
    incorrect: baseButtonStyle + "bg-red-600 text-white hover:bg-red-700 focus:bg-red-700 active:bg-red-800 ",
    neutral:
        baseButtonStyle + "border-2 border-pink-400 text-black hover:bg-pink-400 hover:text-white hover:shadow-2xl transition duration-300 ease-in-out ",
};

function AnswerButton({ disabled, onClick, type, children }) {
    return (
        <button
            className={buttonStyles[type] + "w-[calc(100%_-_32px)] md:w-[calc(50%_-_32px)] lg:w-fit"}
            disabled={disabled}
            onClick={onClick}
        >
            {children}
        </button>
    );
}

export default AnswerButton;
