import React, { useCallback, useRef } from "react";
import ReactPlayer from "react-player/lazy";
import { useEffect, useState } from "react";
import "tw-elements";
import io from "socket.io-client";
import Swal from "sweetalert2";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import HeartIcon from "../components/HeartIcon";
import AnswerButton from "../components/AnswerButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faWarning } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import useAnalytics from "../utils/useAnalytics";

const STATUS = {
    STARTED: "Started",
    STOPPED: "Stopped",
};

const socket = io("/", {});
export default function VersusScreen() {
    const INITIAL_COUNT = 30;
    const [song, setSong] = useState({
        choices: ["", "", "", ""],
        link: "https://www.youtube.com/watch?v=MGF3kdAIRtQ",
        id: "",
        name: "",
    });
    const [user, setUser] = useState({
        name: "",
        answerList: "",
        isAnswered: false,
        replayClicked: false,
        latestAnswer: "",
        hpLeft: 5,
    });
    const [opponent, setOpponent] = useState({
        name: "",
        answerList: "",
        isAnswered: false,
        replayClicked: false,
    });
    const [videoPlaying, setVideoPlaying] = useState(false);

    const [dismissed, setDismissed] = useState(false);
    const [end, setEnd] = useState(false);
    const [openTooltip, setOpenTooltipValue] = useState(null);
    const [status, setStatus] = useState(STATUS.STOPPED);
    const [secondsRemaining, setSecondsRemaining] = useState(INITIAL_COUNT);
    const userRef = useRef(user);
    const opponentRef = useRef(opponent);

    let { id } = useParams();

    useAnalytics();

    useEffect(() => {
        userRef.current = user;
    }, [user]);

    useEffect(() => {
        opponentRef.current = opponent;
    }, [opponent]);

    useEffect(() => {
        window.addEventListener("keydown", (e) => {
            if (e.code === "Space") {
                setVideoPlaying((prev) => !prev);
            }
        });
    }, []);

    const handleStart = () => {
        setStatus(STATUS.STARTED);
    };
    const handleStop = () => {
        setStatus(STATUS.STOPPED);
    };
    const handleReset = () => {
        setStatus(STATUS.STOPPED);
        setSecondsRemaining(INITIAL_COUNT);
    };
    const handleReport = async () => {
        const { value } = await Swal.fire({
            title: "Report Song",
            input: "select",
            inputOptions: {
                SONG: {
                  noLyrics: "This song has too few or no lyrics at all.",
                },
                LANGUAGE: {
                  wrongLanguage: "This song is not in this language!",
                },
                VIDEO: {
                  wrongVideo: "This video does not belong to this song!",
                  removedVideo: "This video is removed!",
                  privateVideo: "This video is private!",
                  regionBlocked: "This video is blocked in my region!",
                  adultVideo: "This video has sexual content!",
                },
            },
            inputPlaceholder: "Report Song",
            showCancelButton: true,
            footer: "Please confirm before reporting! Thank you!",
            inputValidator: (value) => {
                return new Promise((resolve) => {
                    if (value !== "") {
                        resolve();
                    } else {
                        resolve("You need to select valid report option!");
                    }
                });
            },
        });

        if (value) {
            await axios.post("/api/report/", { track: { id: song.id, name: "" }, reportType: value });
        }
    };

    const setStatsForNextQuestion = (data) => {
        handleReset();
        setUser({ ...userRef.current, isAnswered: false, latestAnswer: "", replayClicked: false });
        setOpponent({ ...opponentRef.current, isAnswered: false, replayClicked: false });
        setSong({ ...song, link: data.link, id: data.id, name: data.name, choices: data.choices });
    };

    const handleOpponentLeft = () => {
        setUser({ ...userRef.current, isAnswered: false, answerList: "", hpLeft: 5 });
        setOpponent({ name: "", answerList: "", isAnswered: false, replayClicked: false });
        setEnd(false);
    };

    useInterval(
        () => {
            if (secondsRemaining > 0) {
                setSecondsRemaining(secondsRemaining - 1);
            } else {
                if (!user.isAnswered && !end) {
                    handleStop();
                    socket.emit("picked-lang", { answer: "false", name: userRef.current.name, id, mode: "multi" });
                    handleSolved();
                    console.log("Hp Left :>> ", userRef.current.hpLeft);
                    if (userRef.current.hpLeft === 1) {
                        socket.emit("end");
                        setEnd(true);
                    }
                } else if (end && !userRef.current.replayClicked) {
                    Swal.close();
                    navigate('/');
                    Swal.fire("Disconnected due to Inactivity", "You couldn't ready up in time!", "info");
                }
            }
        },
        status === STATUS.STARTED ? 1000 : null

        // passing null stops the interval
    );

    const handleClose = () => {
        toggleClick(null);
    };

    const navigate = useNavigate();

    const toggleClick = (index) => {
        if (end) setOpenTooltipValue(openTooltip === index ? null : index);
        if (user.isAnswered && !opponent.isAnswered) setOpenTooltipValue(openTooltip === index ? null : index);
    };

    const handleEndGame = useCallback(() => {
        if (userRef.current.hpLeft !== 0) {
            Swal.fire({
                title: `You won!`,

                confirmButtonText: "Would You Like to Play Again?",
                icon: "success",
                allowEscapeKey: false,
                allowOutsideClick: false,
                denyButtonColor: "gray",
                showDenyButton: true,
                showCloseButton: true,
                focusConfirm: true,
                denyButtonText: "Leave!",
                customClass: {
                    actions: "my-actions",
                    confirmButton: "order-1 right-gap",
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    console.log("render oldu");
                    console.log("girdi");
                    setUser({ ...userRef.current, replayClicked: true });
                    handleStart();
                    socket.emit("replay", { id, name: user.name, mode: "multi" });
                } else if (result.isDismissed) {
                    setUser({ ...userRef.current, isAnswered: false });
                    setOpponent({ name: "", isAnswered: false, replayClicked: false });
                    setEnd(false);
                    setDismissed(true);
                    socket.close();
                } else {
                    navigate('/');
                }
            });
        } else {
            Swal.fire({
                title: `Game Over!`,

                confirmButtonText: "Would You Like to Play Again?",
                denyButtonColor: "gray",
                showDenyButton: true,
                denyButtonText: "Leave!",
                icon: "error",
                allowEscapeKey: false,
                allowOutsideClick: false,
                showCloseButton: true,
                focusConfirm: true,
                customClass: {
                    actions: "my-actions",
                    confirmButton: "order-1 right-gap",
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    console.log("render oldu");
                    console.log("girdi");
                    setUser({ ...userRef.current, replayClicked: true });
                    handleStart();
                    socket.emit("replay", { id, name: userRef.current.name, mode: "multi" });
                } else if (result.isDismissed) {
                    setUser({ ...userRef.current, isAnswered: true, latestAnswer: "" });
                    setOpponent({ name: "", isAnswered: false, replayClicked: false });
                    setEnd(true);
                    setDismissed(true);
                    socket.close();
                } else {
                    navigate('/');
                }
            });
        }
    
    //eslint-disable-next-line
    }, [Swal, user.hpLeft]);

    useEffect(() => {
        if (user.name) socket.emit("joinroom", { name: userRef.current.name, id, mode: "multi" });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.name]);

    useEffect(() => {
        socket.on("finish", () => {
            console.log("HOCA BİTTİ HOCA");
            setEnd(true);
            handleReset();
            handleEndGame();
        });

        return () => {
            socket.off("finish");
        };
    }, [handleEndGame, user.hpLeft]);

    useEffect(() => {
        socket.connect();
        userRef.current = user;
        opponentRef.current = opponent;
        socket.on("noRoom", () => {
            Swal.fire({
                title: `Beep-Boop!`,
                html: `The room you are trying to join doesn't exist!`,
                confirmButtonText: "Menu?",
                icon: "error",
                allowEscapeKey: false,
                allowOutsideClick: false,
                customClass: {
                    actions: "my-actions",
                    confirmButton: "order-1 right-gap",
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate('/');
                }
            });
        });
        socket.emit("test");
        socket.on("opponent-true", () => {
            setOpponent({ ...opponentRef.current, isAnswered: true, answerList: opponentRef.current.answerList + "✔️" });
            handleStart();
        });
        socket.on("opponent-false", () => {
            setOpponent({ ...opponentRef.current, answerList: opponentRef.current.answerList + "❌" });
        });
        socket.on("roomFull", () => {
            Swal.fire({
                title: `Room Full!`,
                html: `The room you are trying to enter is full!`,
                confirmButtonText: "Menu?",
                icon: "error",
                allowEscapeKey: false,
                allowOutsideClick: false,
                customClass: {
                    actions: "my-actions",
                    confirmButton: "order-1 right-gap",
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate('/');
                }
            });
        });
        socket.on("question", (data) => {
            console.log(data);
            setSong({ ...song, id: data.id, link: data.link, choices: data.choices });
        });
        socket.on("newQuestion", (data) => {
            setStatsForNextQuestion(data);
        });
        socket.on("restart", (data) => {
            console.log("GOT RESTART");
            setUser({ ...userRef.current, hpLeft: 5, latestAnswer: "", isAnswered: false, answerList: "", replayClicked: "" });
            setOpponent({ ...opponentRef.current, answerList: "", isAnswered: false, replayClicked: false });
            setSong({ link: data.link, id: data.id, name: data.name, choices: data.choices });
            setEnd(false);
            handleReset();
        });
        socket.on("serverError", () => {
            handleReset();
            setUser({ ...userRef.current, isAnswered: false });
            setOpponent({ name: "", isAnswered: false, replayClicked: false });
            setEnd(false);
            socket.close();
            Swal.fire({
              title: `Beep-Boop!`,
              html: `There is an unexpected error!`,
              confirmButtonText: "Please join again!",
              icon: "error",
              allowEscapeKey: false,
              allowOutsideClick: false,
              customClass: {
                actions: "my-actions",
                confirmButton: "order-1 right-gap",
              },
            }).then((result) => {
              if (result.isConfirmed) {
                navigate('/');
            }
            });
          });

        socket.on("opponentReady", () => {
            setOpponent({ ...opponentRef.current, replayClicked: true });
            handleStart();
        });
        socket.on("opponent", (name) => {
            handleReset();
            console.log("opponent geldi");
            console.log(name);
            setOpponent({ ...opponentRef.current, name: name, replayClicked: false });
        });
        socket.on("ping", () => {
            socket.emit("pong", { id, name: user.name });
        });
        socket.on("opponentLeft", (data) => {
            handleStop();
            Swal.fire("Your opponent left!", "Invite someone else with url.", "info");
            setSong({ link: data.link, id: data.id, name: data.name, choices: data.choices });
            handleOpponentLeft();
        });
        socket.on("true", (data) => {
            setUser({ ...userRef.current, isAnswered: true, answerList: userRef.current.answerList.concat("✔️") });
            handleStart();
            handleSolved();
        });
        socket.on("false", (data) => {
            console.log("RECEIVED FALSE");
            setUser({
                ...userRef.current,
                answerList: userRef.current.answerList.concat("❌"),
                hpLeft: userRef.current.hpLeft - 1,
            });

            if (userRef.current.hpLeft === 1) {
                socket.emit("end");
                setEnd(true);
            }
        });
        socket.on("opponent-true", (data) => {
            setOpponent({ ...opponentRef.current, isAnswered: true, answerList: opponentRef.current.answerList.concat("✔️") });

            handleStart();
        });

        socket.on("disconnect", () => {
            handleReset();
            setUser({ ...userRef.current, isAnswered: false });
            setOpponent({ name: "", isAnswered: false, replayClicked: false });
            setEnd(false);
            socket.close();
            Swal.fire({
                title: `Lost connection!`,
                confirmButtonText: "Go back to menu",
                icon: "info",
                allowEscapeKey: false,
                allowOutsideClick: false,
                denyButtonColor: "gray",
                showDenyButton: false,
                showCloseButton: true,
                focusConfirm: true,
                footer: "Please ensure you have stable network connection.",
                customClass: {
                    actions: "my-actions",
                    confirmButton: "order-1 right-gap",
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate('/');
                } else if (result.isDismissed) {
                    setDismissed(true);
                } else {
                    navigate('/');
                }
            });
        });

        socket.on("opponent-false", (data) => {
            setOpponent({ ...opponentRef.current, answerList: opponentRef.current.answerList.concat("❌") });
        });

        return () => {
            socket.removeAllListeners();
            socket.close();
        };
    
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSolved = useCallback(() => {
        console.log("checkkk solved");
        console.log("Name is" + user.name);
        socket.emit("solved", { id, name: userRef.current.name, mode: "multi" });
    }, [id, user.name]);

    const scrollContainer = (event) => {
        event.preventDefault();
        event.target.parentElement.scrollLeft += event.deltaY;
    };

    useEffect(() => {
        const userAnswerContainer = document.getElementById("answer-detail-user");
        if (userAnswerContainer) userAnswerContainer.addEventListener("wheel", scrollContainer);

        const opponentAnswerContainer = document.getElementById("answer-detail-opponent");
        if (opponentAnswerContainer) opponentAnswerContainer.addEventListener("wheel", scrollContainer);

        const nameFunc = async () => {
            await Swal.fire({
                title: "Enter your nick name!",
                input: "text",
                inputLabel: "Name is required in order to join room!",
                inputValue: "",
                showCancelButton: false,
                allowOutsideClick: false,
                showCloseButton: true,
                allowEscapeKey: false,
                footer: "You can return to menu by dismissing this modal!",
                inputValidator: (value) => {
                    if (!value) {
                        return "You need to write something!";
                    }
                },
            }).then((result) => {
                if (result.isDismissed) {
                    navigate('/');
                } else {
                    setUser({ ...userRef.current, name: result.value });
                }
            });
        };
        nameFunc().catch((error) => console.error(error));

        return () => {
            if (userAnswerContainer) userAnswerContainer.removeEventListener("wheel", scrollContainer);
            if (opponentAnswerContainer) opponentAnswerContainer.removeEventListener("wheel", scrollContainer);
        };
    
    //eslint-disable-next-line
    }, []);

    const handleAnswerClick = (clickedAnswer) => {
        setUser({ ...userRef.current, latestAnswer: clickedAnswer });
        console.log("PICKED LANG");
        const pickedLang = clickedAnswer;
        socket.emit("picked-lang", { answer: pickedLang, name: userRef.current.name, id, mode: "multi" });
        console.log(pickedLang);
    };

    return (
        <div class="flex flex-col bg-gradient-to-r md:min-h-[calc(100vh_-_56px)] min-h-[calc(100vh_-_120px)] items-center">
            <div className="flex gap-x-2 items-end">
                <div class="flex flex-col-reverse justify-end my-5">
                    {[0, 0, 0, 0, 0].map((_, i) => (
                        <HeartIcon colorCode={user.hpLeft >= i + 1 ? "fill-red-500" : "fill-black-500 "} />
                    ))}
                </div>

                <div className="flex flex-col align-center justify-center">
                    <div class="flex justify-between mb-5 md:mt-4">
                        <b> {user.name}</b>
                        {opponent.name}
                    </div>
                    <div class="flex justify-between mb-5 max-h-[40px]">
                        <div className="group flex items-center bg-red-500 rounded-xl overflow-hidden transition duration-500 ease-in-out">
                            {/* Wrong Icon */}
                            <div class="h-[40px] w-[40px] flex justify-center items-center bg-red-700">
                                <FontAwesomeIcon icon={faTimes} color="white" />
                            </div>
                            <div
                                id="answer-detail-user"
                                className="thin-scroll flex h-full group-hover:overflow-x-auto group-hover:w-[80px] sm:group-hover:w-[120px] md:group-hover:w[160px] lg:group-hover:w-[240px] xl:group-hover:w-[280px] 2xl:group-hover:w-[320px] detailed-answers w-0 transition-[width] duration-500 ease-in-out overflow-hidden items-center bg-red-400"
                            >
                                {user.answerList.split("✔️").map((answer, index) => {
                                    return <p className="leading-[36px] text-white px-4"><span title={`${index + 1}. Question`}>{answer.length}</span></p>;
                                })}
                            </div>
                            <p className="text-white px-4 leading-10">
                                {user.answerList.split("").reduce((acc, curr) => {
                                    if (curr === "❌") {
                                        return acc + 1;
                                    }
                                    return acc;
                                }, 0)}
                            </p>
                        </div>

                        {opponent.name !== "" && (
                            <div className="group flex items-center bg-red-500 rounded-xl overflow-hidden transition duration-500 ease-in-out">
                            {/* Wrong Icon */}
                            <div class="h-[40px] w-[40px] flex justify-center items-center bg-red-700">
                                <FontAwesomeIcon icon={faTimes} color="white" />
                            </div>
                            <div
                                id="answer-detail-user"
                                className="thin-scroll flex h-full group-hover:overflow-x-auto group-hover:w-[80px] sm:group-hover:w-[120px] md:group-hover:w[160px] lg:group-hover:w-[240px] xl:group-hover:w-[280px] 2xl:group-hover:w-[320px] detailed-answers w-0 transition-[width] duration-500 ease-in-out overflow-hidden items-center bg-red-400"
                            >
                                {opponent.answerList.split("✔️").map((answer, index) => {
                                    return <p className="leading-[36px] text-white px-4"><span title={`${index + 1}. Question`}>{answer.length}</span></p>;
                                })}
                            </div>
                            <p className="text-white px-4 leading-10">
                                {opponent.answerList.split("").reduce((acc, curr) => {
                                    if (curr === "❌") {
                                        return acc + 1;
                                    }
                                    return acc;
                                }, 0)}
                            </p>
                        </div>
                        )}
                    </div>
                    <div class="h-48 md:h-96 xl:h-[calc(50vh)] w-80 md:w-[500px] lg:w-[1080px] justify-center relative">
                        <ReactPlayer
                            class="absolute mt-0 ml-0"
                            onPlay={() => setVideoPlaying(true)}
                            onPause={() => setVideoPlaying(false)}
                            fluid={false}
                            loop={true}
                            controls={true}
                            playing={videoPlaying}
                            height={"100%"}
                            width={"100%"}
                            url={song.link}
                            config={{ youtube: { playerVars: { fs: 0, modestbranding: 1, origin:"https://langoguessr.com",cc_load_policy:0 } } }}
                        />
                        <div
                            className="flex h-[64px] rounded-b-xl w-full items-center justify-center transition duration-300 text-white absolute top-0"
                        >
                            <div className="h-full flex-shrink-0 flex-[4] backdrop-blur-2xl" />
                            <div onClick={handleReport} className="cursor-pointer absolute rounded-b-xl top-0 py-2 px-8 bg-red-800 hover:bg-red-600">
                                <FontAwesomeIcon icon={faWarning} /> Report
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex items-center px-4 gap-2 justify-center mt-8 flex-wrap">
                {song.choices.map((element, index) => (
                    <AnswerButton
                        type={
                            user.latestAnswer === "" || (user.latestAnswer !== element.name && element.name !== "")
                                ? "neutral"
                                : user.latestAnswer === element.name && user.isAnswered === true
                                ? "correct"
                                : "incorrect"
                        }
                        disabled={user.latestAnswer === element.name || user.isAnswered || end || !opponent.name ? true : false}
                        onClick={() => handleAnswerClick(element.name)}
                    >
                        <Tooltip
                            open={openTooltip === index}
                            onOpen={() => toggleClick(index)}
                            onClose={handleClose}
                            title={end ? "Waiting for your opponent to ready up!" : "Waiting for your opponent to answer!"}
                        >
                            <p> {element === "" ? "getting lang" : element.name}</p>
                        </Tooltip>
                    </AnswerButton>
                ))}
            </div>

            {
                //WAITING FOR YOUR ANSWER NOTIFICATION

                !end && !user.isAnswered && opponent.isAnswered && (
                    <div class="bg-white/60 backdrop-blur-xl z-20 max-w-md absolute right-5 top-5 rounded-lg p-6 shadow">
                        <h1 class="text-xl text-slate-700 font-medium">Waiting For Your Answer!</h1>
                        <div class="flex justify-between items-center">
                            <p class="text-slate-500 hover:text-slate-700 text-sm inline-flex space-x-1 items-center">
                                <span>{secondsRemaining} Second(s) left!</span>
                                <div class="flex justify-center items-center">
                                    <div role="status">
                                        <svg
                                            class="inline mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                            viewBox="0 0 100 101"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                fill="currentColor"
                                            />
                                            <path
                                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                fill="currentFill"
                                            />
                                        </svg>
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                            </p>
                        </div>
                    </div>
                )
            }
            {
                //WAITING FOR OPPONENT'S ANSWER NOTIFICATION

                !end && user.isAnswered && !opponent.isAnswered && opponent.name && (
                    <div class="bg-white/60 backdrop-blur-xl z-20 max-w-md absolute right-5 top-5 rounded-lg p-6 shadow">
                        <h1 class="text-xl text-slate-700 font-medium">Waiting For Your Opponent's Answer!</h1>
                        <div class="flex justify-between items-center">
                            <p class="text-slate-500 hover:text-slate-700 text-sm inline-flex space-x-1 items-center">
                                <span>{secondsRemaining} Second(s) left!</span>
                                <div class="flex justify-center items-center">
                                    <div role="status">
                                        <svg
                                            class="inline mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                            viewBox="0 0 100 101"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                fill="currentColor"
                                            />
                                            <path
                                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                fill="currentFill"
                                            />
                                        </svg>
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                            </p>
                        </div>
                    </div>
                )
            }
            {
                //WAITING FOR YOU TO READY UP NOTIFICATOIN

                end && !user.replayClicked && opponent.replayClicked && opponent.name && (
                    <div class="bg-white/60 backdrop-blur-xl z-20 max-w-md absolute right-5 top-5 rounded-lg p-6 shadow">
                        <h1 class="text-xl text-slate-700 font-medium">Waiting For You to Ready Up!</h1>
                        <div class="flex justify-between items-center">
                            <p class="text-slate-500 hover:text-slate-700 text-sm inline-flex space-x-1 items-center">
                                <span>{secondsRemaining} Second(s) left!</span>
                                <div class="flex justify-center items-center">
                                    <div role="status">
                                        <svg
                                            class="inline mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                            viewBox="0 0 100 101"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                fill="currentColor"
                                            />
                                            <path
                                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                fill="currentFill"
                                            />
                                        </svg>
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                            </p>
                        </div>
                    </div>
                )
            }
            {
                //NOTIFICATION FOR WAITING FOR OPPONENT TO READY UP
                end && user.replayClicked && !opponent.replayClicked && opponent.name && (
                    <div class="bg-white/60 backdrop-blur-xl z-20 max-w-md absolute right-5 top-5 rounded-lg p-6 shadow">
                        <h1 class="text-xl text-slate-700 font-medium">Waiting For Your Opponent to Ready Up!</h1>
                        <div class="flex justify-between items-center">
                            <p class="text-slate-500 hover:text-slate-700 text-sm inline-flex space-x-1 items-center">
                                <span>{secondsRemaining} Second(s) left!</span>
                                <div class="flex justify-center items-center">
                                    <div role="status">
                                        <svg
                                            class="inline mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                            viewBox="0 0 100 101"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                fill="currentColor"
                                            />
                                            <path
                                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                fill="currentFill"
                                            />
                                        </svg>
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                            </p>
                        </div>
                    </div>
                )
            }

            {
                //NOTIFICATION FOR READY UP

                dismissed && (
                    <div class="bg-purple-100 backdrop-blur-xl z-20 max-w-md absolute right-5 top-5 rounded-lg p-6 shadow">
                        <h1 class="text-xl text-slate-700 font-medium">WARNING! YOU ARE DISCONNECTED FROM LOBBY!</h1>
                        <div class="flex justify-between items-center">
                            <p class="text-slate-500 hover:text-slate-700 text-sm inline-flex space-x-1 items-center">
                                <span onClick={()=>navigate('/')} style={{ cursor: "pointer" }}>
                                    Click to go back to main menu!
                                </span>
                                <div class="flex justify-center items-center"></div>
                            </p>
                        </div>
                    </div>
                )
            }
        </div>
    );
}

function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}