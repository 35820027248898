import { faPeopleGroup, faPerson } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router";
import "tw-elements";
import OptionCard from "../components/OptionCard";
import useAnalytics from "../utils/useAnalytics";

export default function LandingPage({ setVideoPlayerMode, videoPlayerMode }) {
    const navigate = useNavigate();

    useAnalytics();

    const onSoloModeSelection = () => {
        navigate("/solo");
    };

    const onMultiModeSelection = () => {
        navigate("/versus");
    };

    const onVideoPlayerModeChange = (mode) => {
        localStorage.setItem("videoPlayerMode", mode);
        setVideoPlayerMode(mode);
    };

    return (
        <div className="flex justify-center items-center md:min-h-[calc(100vh_-_56px)] min-h-[calc(100vh_-_120px)] flex-col 2xl:flex-row">
            <div className="my-12 px-12 flex items-center justify-center flex-col">
                <h1 className="text-4xl font-bold ">
                    Welcome to <span className="text-blue-500">LangoGuessr</span>!
                </h1>
                <p className="text-lg mt-4">Listen musics from all around the world, then guess the language.</p>
                <div className="flex my-6 gap-4">
                    <div className="flex flex-col items-center">
                        <button
                            onClick={() => onVideoPlayerModeChange("video")}
                            className={`transition-shadow duration-200 border-blue-400 border-2 p-4 rounded-md hover:shadow-xl hover:scale-105 ${
                                videoPlayerMode === "video" && "bg-blue-400 text-white"
                            }`}
                        >
                            Video&Audio
                        </button>
                    </div>
                    <div className="flex flex-col items-center">
                        <button
                            onClick={() => onVideoPlayerModeChange("audio")}
                            className={`transition-shadow duration-200 border-blue-400 border-2 p-4 rounded-md hover:shadow-xl hover:scale-105 ${
                                videoPlayerMode === "audio" && "bg-blue-400 text-white"
                            }`}
                        >
                            Audio Only
                        </button>
                        <div className="mt-2 text-xs text-center text-gray-500">
                            * WIP: Temporary Solution
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-wrap justify-center">
                <OptionCard
                    title="Single Player"
                    subtitle="I am fine by myself!"
                    icon={faPerson}
                    onClick={onSoloModeSelection}
                    variant="blue"
                    description="Pick and play one of the game modes and challenge yourself!"
                    buttonText="Play"
                />
                <OptionCard
                    title="Multiplayer"
                    subtitle="Let me flex my linguistic skills!"
                    icon={faPeopleGroup}
                    onClick={onMultiModeSelection}
                    variant="red"
                    description="Invite a friend of yours or let us match you with someone from around the globe!"
                    buttonText="Play"
                />
            </div>
        </div>
    );
}
