import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import CreateRoomScreen from "./Screens/CreateRoomScreen";
import InfiniteWithScoreScreen from "./Screens/InfiniteWithScoreScreen";
import VersusScreen from "./Screens/VersusScreen";
import LandingPage from "./Screens/LandingPage";
import MultiModeSelectionScreen from "./Screens/MultiModeSelectionScreen";
import PairRoomScreen from "./Screens/PairRoomScreen";
import LeaderboardScreen from "./Screens/LeaderboardScreen";
import InfiniteWithTimerScreen from "./Screens/InfiniteWithTimerScreen";
import ClassicWithTimerScreen from "./Screens/ClassicWithTimerScreen";
import ClassicWithScoreScreen from "./Screens/ClassicWithScoreScreen";
import SoloModeSelectionScreen from "./Screens/SoloModeSelectionScreen";
import "./App.css";
import Header from "./components/Header";
import { useEffect, useState } from "react";
import CertificateScreen from "./Screens/CertificateScreen";

if (process.env.NODE_ENV === "production") {
    console.log = () => {};
    console.warn = () => {};
    console.error = () => {};
}

function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
        let bmc = document.getElementById("bmc-wbtn");
        if (bmc && pathname.match(/(solo|versus)\/\w+/)) {
            bmc.style.display = "none";
        } 
    }, [pathname]);

    return null;
}

function App() {
    const [videoPlayerMode, setVideoPlayerMode] = useState("video");

    useEffect(() => {
        const videoPlayerMode = localStorage.getItem("videoPlayerMode");
        if (!videoPlayerMode) {
            localStorage.setItem("videoPlayerMode", "video");
            return;
        }
        setVideoPlayerMode(videoPlayerMode);
    }, []);

    return (
        <BrowserRouter>
            <ScrollToTop />
            <div className="flex flex-col w-screen bg-gradient-to-r from-cyan-300 via-purple-200 to-pink-300 pb-8 lg:pb-0">
                <div className="h-14 w-screen mb-8 md:mb-0">
                    <Header />
                </div>
                <Routes>
                    <Route path="/" exact element={<LandingPage videoPlayerMode={videoPlayerMode} setVideoPlayerMode={setVideoPlayerMode} />} />
                    <Route path="/solo" exact element={<SoloModeSelectionScreen />} />
                    <Route path="/solo/infinite" exact element={<InfiniteWithScoreScreen />} />
                    <Route path="/solo/infinite-with-timer" exact element={<InfiniteWithTimerScreen />} />
                    <Route path="/solo/classic" exact element={<ClassicWithScoreScreen />} />
                    <Route path="/solo/classic-with-timer" exact element={<ClassicWithTimerScreen />} />
                    <Route path="/versus" exact element={<MultiModeSelectionScreen />} />
                    <Route path="/versus/create-room" exact element={<CreateRoomScreen />} />
                    <Route path="/versus/:id" exact element={<VersusScreen />} />
                    <Route path="/certificate/:id" exact element={<CertificateScreen />} />
                    <Route path="/versus/pair-room" exact element={<PairRoomScreen />} />
                    <Route path="/leaderboard" exact element={<LeaderboardScreen />} />
                    <Route path="/" element={<LandingPage videoPlayerMode={videoPlayerMode} />} />
                </Routes>
            </div>
        </BrowserRouter>
    );
}

export default App;
