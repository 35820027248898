import { useEffect, useState } from "react";
import { io } from "socket.io-client";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import "animate.css";
import useAnalytics from "../utils/useAnalytics";

const socket = io("/", {});

export default function PairRoomScreen() {
    const [searching, setSearching] = useState(false);
    const navigate = useNavigate();
    useAnalytics();

    useEffect(() => {
        if (searching) {
            socket.emit("pair");
            Swal.fire({
                title: "Waiting for Opponent",
                text: "We will redirect you as soon as we find an opponent for you",
                showDenyButton: true,
                denyButtonText: "Leave queue",
                allowOutsideClick: false,
                allowEscapeKey: false,
                showConfirmButton: false,
            }).then((result) => {
                if (result.isDenied) {
                    Swal.close();
                    setSearching(false);
                    socket.emit("leave-queue");
                    navigate("/");
                }
            });
        }
    }, [searching, navigate]);

    useEffect(() => {
        socket.connect();
        socket.on("paired", (data) => {
            console.log("EMITTING THIS");
            socket.emit("socket-ready", { id: data.id, opponentId: data.opponentId });
        });
        socket.on("game-ready", (data) => {
            console.log("GOT GAME READY");
            navigate(`/versus/${data.id}`);
        });
        socket.on("disconnect", () => {
            Swal.close();
            console.log("GOT DISCONNECT");
            setSearching(false);
            navigate("/");
        });
        Swal.fire({
            title: `Match up!`,
            html: `We will match you after you click on Pair Me button!`,
            confirmButtonText: "Pair me!",
            icon: "info",
            allowOutsideClick: false,
            allowEscapeKey: false,
        }).then((result) => {
            if (result.isConfirmed) {
                console.log("confirmed");
                setSearching(true);
            }
        });
        return () => {
            Swal.close();
            socket.removeAllListeners();
            socket.close();
        };

    //eslint-disable-next-line
    }, []);

    return <></>;
}
