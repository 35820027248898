import { faShareSquare } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PDFDocument } from "pdf-lib";
import { useEffect, useRef, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router";
import Swal from "sweetalert2";
import useAnalytics from "../utils/useAnalytics";
import * as analytics from "../utils/ga4";


export default function CertificateScreen() {
  const [pdfInfo, setPdfInfo] = useState([]);
  const success=useRef(false)

  let { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    modifyPdf();
  
  // eslint-disable-next-line
  }, []);
  useAnalytics();

  const shareAction = () => {
    if (navigator.share) {
      analytics.sendSocialEvent("url-share");
      navigator
        .share({
          title: "Langoguessr",
          url: `https://langoguessr.com/certificate/${id}`,
        })
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing", error));
    }
  };

  const generateLinkedinUrl = () => {
    const date = new Date();
    const url = `https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME`;
    const name = "Langoistic Certificate";
    const organizationId = "88396596";
    const issueYear = date.getFullYear();
    const issueMonth = date.getMonth() + 1;
    const certUrl = `https://langoguessr.com/certificate/${id}`;
    return `${url}&name=${encodeURI(
      name
    )}&organizationId=${organizationId}&issueYear=${issueYear}&issueMonth=${issueMonth}&certUrl=${encodeURI(certUrl)}`;
  };

  const modifyPdf = async () => {
    const existingPdfBytes = await fetch(`/api/certificate/${id}`).then((res) => res.arrayBuffer());

    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    // Get the width and height of the first page

    const pdfBytes = await pdfDoc.save();
    const docUrl = URL.createObjectURL(new Blob([pdfBytes], { type: "application/pdf" }));
    setPdfInfo(docUrl);
  };
  useEffect(() => {
    Swal.fire({
      icon: "info",
      title: "Please Wait!",
      html: "Preparing certificate.",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCloseButton: true,
      didOpen: () => {
        Swal.showLoading();
      }
    }).then(result=>{
      if(!success.current&&Swal.DismissReason.close){
        navigate('/');
      }
    })
  }, []);

  useEffect(() => {
    if (typeof pdfInfo === "string") {
      success.current=true;
      Swal.close();
    }
  }, [pdfInfo]);

  return (
    <div class="flex flex-col bg-gradient-to-r md:min-h-[calc(100vh_-_56px)] min-h-[calc(100vh_-_120px)] items-center">
      <div class="flex my-4 w-full justify-center">
        <button
          className="h-[60px] font-normal flex items-center gap-x-3 rounded-[8px] px-6 bg-white"
          onClick={shareAction}
        >
          <FontAwesomeIcon className="text-lg" icon={faShareSquare} />
          Share
        </button>
      </div>
      <div class="flex-[8] relative h-full flex flex-col items-center justify-center aspect-[16/13]">
        <iframe
          className="absolute top-0 w-full h-full"
          width="100%"
          height="100%"
          title="test-frame"
          src={pdfInfo}
          frameborder="0"
          type="application/pdf"
        />
      </div>
      <div className="flex flex-[3] ">
        <div className="flex justify-center md:justify-between items-center w-full gap-4 flex-wrap content-center ">
          <a onClick={()=>analytics.sendSocialEvent("join-discord")} href="https://discord.gg/mStEjq4Nm6" target="_blank" rel="noreferrer">
            <button className="flex items-center gap-x-3 h-[60px] bg-[#5562ea] rounded-[8px] px-6 text-lg hover:bg-[#6e79f1]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40px"
                height="40px"
                viewBox="0 -28.5 256 256"
                version="1.1"
                preserveAspectRatio="xMidYMid"
              >
                <g>
                  <path
                    d="M216.856339,16.5966031 C200.285002,8.84328665 182.566144,3.2084988 164.041564,0 C161.766523,4.11318106 159.108624,9.64549908 157.276099,14.0464379 C137.583995,11.0849896 118.072967,11.0849896 98.7430163,14.0464379 C96.9108417,9.64549908 94.1925838,4.11318106 91.8971895,0 C73.3526068,3.2084988 55.6133949,8.86399117 39.0420583,16.6376612 C5.61752293,67.146514 -3.4433191,116.400813 1.08711069,164.955721 C23.2560196,181.510915 44.7403634,191.567697 65.8621325,198.148576 C71.0772151,190.971126 75.7283628,183.341335 79.7352139,175.300261 C72.104019,172.400575 64.7949724,168.822202 57.8887866,164.667963 C59.7209612,163.310589 61.5131304,161.891452 63.2445898,160.431257 C105.36741,180.133187 151.134928,180.133187 192.754523,160.431257 C194.506336,161.891452 196.298154,163.310589 198.110326,164.667963 C191.183787,168.842556 183.854737,172.420929 176.223542,175.320965 C180.230393,183.341335 184.861538,190.991831 190.096624,198.16893 C211.238746,191.588051 232.743023,181.531619 254.911949,164.955721 C260.227747,108.668201 245.831087,59.8662432 216.856339,16.5966031 Z M85.4738752,135.09489 C72.8290281,135.09489 62.4592217,123.290155 62.4592217,108.914901 C62.4592217,94.5396472 72.607595,82.7145587 85.4738752,82.7145587 C98.3405064,82.7145587 108.709962,94.5189427 108.488529,108.914901 C108.508531,123.290155 98.3405064,135.09489 85.4738752,135.09489 Z M170.525237,135.09489 C157.88039,135.09489 147.510584,123.290155 147.510584,108.914901 C147.510584,94.5396472 157.658606,82.7145587 170.525237,82.7145587 C183.391518,82.7145587 193.761324,94.5189427 193.539891,108.914901 C193.539891,123.290155 183.391518,135.09489 170.525237,135.09489 Z"
                    fill="white"
                    fill-rule="nonzero"
                  />
                </g>
              </svg>
              <p className="text-white font-normal">Join Community!</p>
            </button>
          </a>
          {/* <FontAwesomeIcon icon={faDiscord} /> */}
          <a onClick={()=>analytics.sendSocialEvent("visit-buymeacoffee")} href="https://www.buymeacoffee.com/LangoGuessr" target="_blank" rel="noreferrer">
            <img
              src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png"
              alt="Buy Me A Coffee"
              className="h-[60px] w-[217px]"
            />
          </a>

          <a
            aria-label="Add certificate to LinkedIn profile"
            data-testid="add-to-profile-button"
            target="_blank"
            rel="noreferrer"
            class="h-[60px] flex items-center gap-x-3 bg-[#3A10E5] hover:bg-[#5533FF] rounded-[8px] px-6 text-white font-bold text-lg"
            onClick={()=>analytics.sendSocialEvent("add-linkedin")}
            href={generateLinkedinUrl()}
          >
            <div class="bg-white rounded-sm p-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="#1557FF"
                role="img"
                width="16px"
                height="16px"
                aria-hidden="true"
              >
                <title>Linkedin Icon</title>
                <path
                  fill="#1557FF"
                  d="M5.6 8.2c0-1.4 0-2.5-.1-3.5h2.9l.2 1.5h.1c.4-.7 1.5-1.8 3.4-1.8 2.2 0 3.9 1.5 3.9 4.7v6.4h-3.4v-6c0-1.4-.5-2.4-1.7-2.4-.9 0-1.5.6-1.7 1.3-.2.3-.2.6-.2.9v6.3H5.6V8.2zM.1 4.9h3.4v10.9H.1V4.9zm3.5-3c0 .9-.7 1.7-1.8 1.7S0 2.8 0 1.9C0 .9.7.2 1.8.2 3 .2 3.6.9 3.6 1.9z"
                ></path>
              </svg>
            </div>
            <p className="font-normal">Add to Profile</p>
          </a>
          {/* Share button with icon */}
        </div>
      </div>
    </div>
  );
}
