import ga4 from 'react-ga4'

const TRACKING_ID = process.env.REACT_APP_GA


export const init = () => ga4.initialize(TRACKING_ID, { 
})

export const sendEvent = (name) => ga4.event('screen_view', {
  app_name: "langoguessr",
  screen_name: name,
})

export const sendPageview = (path) => ga4.send({ 
  hitType: 'pageview', 
  page: path 
})

export const sendSocialEvent=(tag)=>ga4.event({
  category: "social_links",
  action: tag,
  nonInteraction: false, 
  transport: "xhr",
});