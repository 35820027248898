import { useState, useEffect } from "react";
import { io } from "socket.io-client";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import "animate.css";
import useAnalytics from "../utils/useAnalytics";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard } from "@fortawesome/free-regular-svg-icons";
import { renderToString } from "react-dom/server";

const { v4: uuidv4 } = require("uuid");

const socket = io("/", {});
export default function CreateRoomScreen() {
    const [leaving, setLeaving] = useState(false);
    const navigate = useNavigate();
    const id = uuidv4();
    const url = `https://${window.location.host}/versus/${id}`;

    useAnalytics();

    useEffect(() => {
        socket.connect();
        socket.emit("createTest", id);
        return () => {
            setLeaving(true);
            socket.removeAllListeners();
            socket.close();
            Swal.close();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClick = () => {
        navigate(`/versus/${id}`);
    };

    const handleReject = () => {
        navigate(`/versus`);
    };
    let timerInterval;
    Swal.fire({
        title: "Preparing a Very Special Room for You!",
        html: "I will close in <b></b> milliseconds.",
        timer: 3000,
        timerProgressBar: true,
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
            Swal.showLoading();
            const b = Swal.getHtmlContainer().querySelector("b");
            timerInterval = setInterval(() => {
                b.textContent = Swal.getTimerLeft();
            }, 100);
            if (leaving) Swal.close();
        },
        willClose: () => {
            clearInterval(timerInterval);
        },
    }).then((result) => {
        if (result.dismiss === "timer") {
            Swal.fire({
                title: "<strong>Invite Your Friend</strong>",
                icon: "info",
                html: `<div style="display:flex"><input disabled="disabled" style="width:100%;font-size:16px;padding:0 8px;background-color:#cfcfcf;outline:0;height:40px;color:#000;border-top-left-radius:8px;border-bottom-left-radius:8px" value="${url}"><div onclick="navigator.clipboard.writeText('${url}');this.classList.add('copied');setTimeout(() => {this.classList.remove('copied')}, 2000)" onmouseover='this.style.backgroundColor="#666666"' onmouseout='this.style.backgroundColor="#9f9f9f"' style="position:relative;height:40px;width:40px;display:flex;justify-content:center;align-items:center;border-top-right-radius:8px;border-bottom-right-radius:8px;background-color:#9f9f9f;color:#fff">${renderToString(<FontAwesomeIcon icon={faClipboard} />)}</div></div><p style="font-size:medium;margin-top:16px">Share this link with your friend to play together!</p>`,
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonText: "Continue",
                cancelButtonText: "Cancel",
                focusConfirm: true,
                willClose: window.history.state && window.history.state.idx > 0,
                showClass: {
                    popup: "animate__animated animate__fadeInDown animate__delay",
                },
            }).then((response) => {
                if (response.timer > 0) {
                    Swal.showLoading();
                }
                if (response.isConfirmed) {
                    handleClick();
                } else {
                    handleReject();
                }
            });
        }
    });

    return <></>;
}
