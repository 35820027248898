import LeaderboardTab from "../components/LeaderboardTab";
import useAnalytics from "../utils/useAnalytics";
export default function LeaderboardScreen() {
    useAnalytics()

    return (
        <div class="md:min-h-[calc(100vh_-_56px)] min-h-[calc(100vh_-_120px)] items-center flex justify-evenly flex-col lg:flex-row gap-y-12 pt-12 pb-24">
            <div class="flex flex-col w-2/3 lg:w-1/3  ">
                <div class="flex flex-col gap-y-12 ">
                    <LeaderboardTab
                        title="Classic with Score"
                        tooltipText="Score for finding varies on the wrong amount you have on that question. 0:20, 1:15, 2:5, 3:1."
                        gamemode="ClassicWithScore"
                    />
                    <LeaderboardTab
                        title="Classic with Timer"
                        tooltipText="In this game mode, score is the time you guessed in 15 questions. Each wrong prediction adds 10 seconds to the timer."
                        gamemode="ClassicWithTimer"
                    />
                </div>
            </div>

            <div class="flex flex-col w-2/3 lg:w-1/3">
                <div class="flex flex-col gap-y-12 ">
                    <LeaderboardTab
                        title="Infinite with HP Limit"
                        tooltipText="In this game mode, player starts with 5 HP. Each wrong prediction removes one health. Score for finding the answer is evaluated on the formula of score + Player's Hp for every question."
                        gamemode="infinite"
                    />
                    <LeaderboardTab
                        title="Infinite with Timer Limit"
                        tooltipText="In this game mode, score is the amount of the question player has answered within 5 minutes. Each wrong prediction removes 10 seconds from the clock."
                        gamemode="infiniteWithTimer"
                    />
                </div>
            </div>
        </div>
    );
}