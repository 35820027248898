import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import * as analytics from "../utils/ga4";

const Header = () => (
    <nav style={{ fontFamily: "Poppins" }} class="flex items-center justify-between flex-wrap bg-teal py-6 px-6 lg:px-12">
        <div class="items-center flex-no-shrink text-black">
            <Link to="/" class="hidden sm:flex font-semibold text-xl tracking-tight">
                LangoGuessr
            </Link>
            <Link to="/" class="flex sm:hidden">
                <FontAwesomeIcon class="h-7 w-7" color="black" icon={faHome} size="2x" />
            </Link>
        </div>
        <div class="text-lg flex items-center">
            <Link
                to="/solo"
                class=" hidden md:block lg:inline-block text-teal-lighter hover:text-white transition-transform duration-300 ease-out hover:scale-110 mr-4"
            >
                Solo
            </Link>
            <Link
                to="/versus"
                class=" hidden md:block lg:inline-block text-teal-lighter hover:text-white transition-transform duration-300 ease-out hover:scale-110 mr-4"
            >
                Online
            </Link>
            <Link
                to="/leaderboard"
                class="block lg:inline-block text-teal-lighter hover:text-white transition-transform duration-300 ease-out hover:scale-110 mr-4"
            >
                Leaderboard
            </Link>
            <a
                href={"https://linktr.ee/langoguessr"}
                onClick={() => analytics.sendSocialEvent("visit-linktree")}
                target="_blank"
                rel="noopener noreferrer"
                class="block lg:inline-block text-teal-lighter cursor-pointer hover:text-white transition-transform duration-300 ease-out hover:scale-110 mr-4"
            >
                Find Us
            </a>
        </div>
    </nav>
);
export default Header;
