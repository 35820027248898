import { faGlobe, faUser, faUserGroup } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { useNavigate } from "react-router";
import "tw-elements";
import OptionCard from "../components/OptionCard";
import Swal from "sweetalert2";
import useAnalytics from "../utils/useAnalytics";

export default function MultiModeSelectionScreen() {
  const navigate = useNavigate();

  useAnalytics()
  return (
    <div className="flex justify-center flex-wrap items-center md:min-h-[calc(100vh_-_56px)] min-h-[calc(100vh_-_120px)]">
      <OptionCard
        title="Friendly Fire"
        subtitle="Let your brain talk my friend!"
        description="Create a room to play with your friend and see who is the best!"
        icon={faUserGroup}
        onClick={() => {
          navigate("create-room");
        }}
        variant="blue"
        buttonText="Play"
        onLeave={()=>Swal.close()}

      />
      <OptionCard
        title="Globe Friend"
        subtitle="Everyone will know!"
        description="You will be matched with a random player from all over the world."
        icon={faGlobe}
        cornerIcon={faUser}
        onClick={() => {
          navigate("pair-room");
        }}
        variant="red"
        buttonText="Play"

      />
    </div>
  );
}
