// The song was <a href=${
//     song.link
//   } target="_blank" rel="noopener noreferrer"><strong>${answerInfo.track_name}</strong></a> by <strong>${
//     answerInfo.artist_name
//   }</strong> <br>

import { faMusic, faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function SongDetailCard({ answerInfo, externalLink, variant }) {

    return (
        <div
            className={`flex items-center my-8 justify-between border-2 pr-4 ${
                variant === "success" ? "border-green-300 " : "border-red-300"
            } rounded-xl`}
        >
            <div
                className={`h-[80px] w-[80px] flex items-center justify-center  ${
                    variant === "success" ? "bg-green-400 " : "bg-red-400"
                } bg-red-400 rounded-lg`}
            >
                <FontAwesomeIcon size="2x" icon={faMusic} className={variant === "success" ? "text-green-800" : "text-red-800"} />
            </div>
            <div className="flex-1 w-1/3 mx-4 flex flex-col items-start text-start">
                <p className="w-full text-lg font-semibold text-ellipsis truncate">{answerInfo.track_name}</p>
                <p className="w-full text-ellipsis truncate text-sm">{answerInfo.artist_name}</p>
            </div>

            <a href={externalLink} target="_blank" rel="noreferrer">
                <button
                    className={`flex-shrink-0 flex-0 min-w-[50px] min-h-[50px] border-2 transition-colors duration-200 ${
                        variant === "success" ? "border-green-500 hover:bg-green-500" : "border-red-500 hover:bg-red-500"
                    }  flex items-center justify-center p-3 rounded-xl`}
                >
                    <FontAwesomeIcon
                        size="1x"
                        icon={faUpRightFromSquare}
                        className={"hover:text-white" + variant === "success" ? "text-green-800" : "text-red-800"}
                    />
                </button>
            </a>
        </div>
    );
}

export default SongDetailCard;
