import axios from "axios";
import { useEffect, useState } from "react";

export default function Leaderboard(props) {
  const [list, setList] = useState([]);

  const [endIndex, setEndIndex] = useState(5);
  const [page, setPage] = useState(1);
  const [activeTab, setActiveTab] = useState("Monthly");

  useEffect(() => {
    const fetchList = async () => {
      console.log("in fetch");
      console.log("IN LEADERBOARD GAMEMODE");
      console.log(props.gamemode);
      const response = await axios
        .post("/api/leaderboard/", {
          listName: props.gamemode,
        })
        .catch((error) => console.error(error));

      const list =
        activeTab === "Monthly"
          ? response.data.monthlyList
          : response.data.list;
      let newList = [];
      if (props.gamemode === "ClassicWithTimer") {
        newList = list.map((element, index) => (
          <tr
            class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100"
            key={element.value}
          >
            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
              {index + 1}
            </td>
            <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
              {element.value}
            </td>
            <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
              {(element.score - (element.score % 60)) / 60}:
              {element.score % 60 < 10
                ? "0" + (element.score % 60)
                : element.score % 60}
            </td>
          </tr>
        ));
      } else {
        newList = list.map((element, index) => (
          <tr
            class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100"
            key={element.value}
          >
            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
              {index + 1}
            </td>
            <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
              {element.value}
            </td>
            <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
              {element.score}
            </td>
          </tr>
        ));
      }

      while (newList.length % 5 !== 0 || newList.length < 5) {
        newList.push(
          <tr
            class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100"
            key={newList.length + 1}
          >
            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
              {newList.length + 1}
            </td>
            <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
              -
            </td>
            <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
              -
            </td>
          </tr>
        );
      }
      setList(newList);
    };
    fetchList();
  }, [props.gamemode, activeTab]);

  useEffect(() => {
    setEndIndex(page * 5);
  }, [page]);

  const handleNextPageClick = () => {
    setPage(page + 1);
  };
  const handlePreviousPageClick = () => {
   if(page>0) setPage(page - 1);
  };

  return (
    <div class="flex flex-col-reverse md:flex-row">
      {(true || list.length !== 0) && (
        <div class="flex flex-col">
          <div class="pb-2 hidden md:block">
            <button
              disabled
              style={{ visibility: "hidden" }}
              class="hidden md:inline-flex py-2 px-4 text-sm font-medium rounded-lg border"
            >
              T
            </button>
          </div>
          <div
            style={{ visibility: "hidden" }}
            class="hidden md:inline-flex text-sm font-medium text-gray-900 py-4"
          >
            #
          </div>
          <div class="flex flex-1 flex-row md:flex-col min-h-8">
            <div
              class="min-h-[40px] items-center overflow-hidden rounded-bl-xl rounded-br-xl md:rounded-bl-xl md:rounded-br-none md:rounded-tl-xl relative flex-1 h-full w-full flex cursor-pointer"
              onClick={() => {
                setActiveTab("Monthly");
                setPage(1);
              }}
            >
              <div
                class={`z-0 absolute w-full h-full  ${
                  activeTab === "Monthly" ? "bg-white" : "bg-[#e8eaed]"
                }`}
              ></div>
              <p class="justify-center z-10 h-full w-full inline-flex items-center md:-rotate-90 text-sm font-medium disabled:opacity-50 text-gray-500 hover:text-gray-700">
                Monthly
              </p>
            </div>
            <div
              class="min-h-[40px] items-center overflow-hidden rounded-bl-xl rounded-br-xl md:rounded-br-none md:rounded-bl-xl md:rounded-tl-xl relative flex-1 h-full w-full flex cursor-pointer"
              onClick={() => {
                setActiveTab("AllTime");
                setPage(1);
              }}
            >
              <div
                class={`z-0 absolute w-full h-full  ${
                  activeTab === "AllTime" ? "bg-white" : "bg-[#e8eaed]"
                }`}
              ></div>
              <p
                disabled
                class="justify-center z-10 h-full w-full inline-flex items-center md:-rotate-90 text-sm font-medium disabled:opacity-50 text-gray-500 hover:text-gray-700"
              >
                All Time
              </p>
            </div>
          </div>
        </div>
      )}
      <div class="flex flex-1 flex-col">
        <div class="flex justify-between">
          <button
            class="inline-flex items-center py-2 px-4 text-sm font-medium text-gray-500 bg-white rounded-lg border border-stone-300 hover:bg-stone-100 hover:text-gray-700 disabled:opacity-50"
            disabled={page === 1 ? true : false}
            onClick={handlePreviousPageClick}
          >
            Previous
          </button>
          <div class="flex space-x-2 justify-center">
            {endIndex - 4} - {endIndex}{" "}
          </div>

          <button
            class="inline-flex items-center py-2 px-4 ml-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-stone-300 hover:bg-stone-100 hover:text-gray-700 disabled:opacity-50"
            onClick={handleNextPageClick}
            disabled={endIndex + 1 > list.length ? true : false}
          >
            Next
          </button>
        </div>

        <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="pt-2 flex min-w-full sm:px-6 lg:px-8">
            <div class="flex-1">
              <table class="min-w-full">
                <thead class="bg-white border-b">
                  <tr>
                    <th
                      scope="col"
                      class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                    >
                      #
                    </th>
                    <th
                      scope="col"
                      class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                    >
                      Score
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {list.length > 1 && list.slice(endIndex - 5, endIndex)}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
