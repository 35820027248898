import React, { useCallback, useRef } from "react";
import ReactPlayer from "react-player/lazy";
import { useEffect, useState } from "react";
import "tw-elements";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/free-solid-svg-icons";
import AnswerButton from "../components/AnswerButton";
import io from "socket.io-client";
import useAnalytics from "../utils/useAnalytics";
import { renderToString } from "react-dom/server";
import SongDetailCard from "../components/SongDetailCard";

const { v4: uuidv4 } = require("uuid");
const socket = io("/", {});
const short = require("short-uuid");
const axios = require("axios").default;

const STATUS = {
  STARTED: "Started",
  STOPPED: "Stopped",
};

export default function ClassicWithTimerScreen() {
  const INITIAL_COUNT = 0;

  const [song, setSong] = useState({
    choices: ["", "", "", ""],
    link: "https://www.youtube.com/watch?v=MGF3kdAIRtQ",
    id: "",
    name: "",
    questionCount: 1,
  });
  const [user, setUser] = useState({
    name: "",
    id: "",
    latestAnswer: "",
    isAnswered: false,
  });

  const [answerInfo, setAnswerInfo] = useState({
    track_name: "",
    artist_name: "",
    language_name: "",
    language_code: "",
    language_native_name: "",
  });

  const [id, setId] = useState();
  const certificateId = uuidv4();

  const [videoPlaying, setVideoPlaying] = useState(false);
  const [disconnected, setDisconnected] = useState(false);
  const [secondsRemaining, setSecondsRemaining] = useState(INITIAL_COUNT);
  const [end, setEnd] = useState(false);
  const [status, setStatus] = useState(STATUS.STOPPED);
  const [animateClockEffect, setAnimateClockEffect] = useState(false);

  const userRef = useRef(user);
  const songRef = useRef(song);
  const secondsRef = useRef(secondsRemaining);
  const secondsToDisplay = secondsRemaining % 60;
  const minutesRemaining = (secondsRemaining - secondsToDisplay) / 60;
  const minutesToDisplay = minutesRemaining % 60;
  const videoPlayerMode = localStorage.getItem("videoPlayerMode");

  useAnalytics();

  useEffect(() => {
    window.addEventListener("keydown", (e) => {
      if (e.code === "Space") {
        setVideoPlaying((prev) => !prev);
      }
    });
  }, []);

  const handleStart = () => {
    setStatus(STATUS.STARTED);
  };
  const handleStop = () => {
    setStatus(STATUS.STOPPED);
  };

  const setStatsForNextQuestion = (data) => {
    setSong({
      ...songRef.current,
      id: data.id,
      link: data.link,
      choices: data.choices,
      questionCount: songRef.current.questionCount + 1,
    });
    setUser({ ...userRef.current, isAnswered: false, latestAnswer: "" });
  };

  const setStatsForRestart = (data) => {
    setSecondsRemaining(INITIAL_COUNT);
    setSong({
      ...songRef.current,
      id: data.id,
      link: data.link,
      choices: data.choices,
      questionCount: 1,
    });
    setUser({ ...userRef.current, isAnswered: false, latestAnswer: "" });
    setEnd(false);
    handleStart();
    console.log("DATA ISS");
    console.log(data);
  };
  useEffect(() => {
    socket.connect();
    userRef.current = user;
    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (!id) return;
    socket.on("noRoom", () => {
      Swal.fire({
        title: `Beep-Boop!`,
        html: `The room you are trying to join doesn't exist!`,
        confirmButtonText: "Menu?",
        icon: "error",
        allowEscapeKey: false,
        allowOutsideClick: false,
        customClass: {
          actions: "my-actions",
          confirmButton: "order-1 right-gap",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          handleMenu();
        }
      });
    });
    socket.emit("test");

    socket.on("serverError", () => {
      Swal.fire({
        title: `Beep-Boop!`,
        html: `There is an unexpected error!`,
        confirmButtonText: "Please join again!",
        icon: "error",
        allowEscapeKey: false,
        allowOutsideClick: false,
        customClass: {
          actions: "my-actions",
          confirmButton: "order-1 right-gap",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          handleMenu();
        }
      });
    });

    socket.on("roomFull", () => {
      Swal.fire({
        title: `Room Full!`,
        html: `The room you are trying to enter is full!`,
        confirmButtonText: "Menu?",
        icon: "error",
        allowEscapeKey: false,
        allowOutsideClick: false,
        customClass: {
          actions: "my-actions",
          confirmButton: "order-1 right-gap",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          handleMenu();
        }
      });
    });
    socket.on("question", (data) => {
      console.log(data);
      handleStart();
      setSong({ ...song, id: data.id, link: data.link, choices: data.choices });
    });
    socket.on("newQuestion", (data) => {
      console.log("GOT NEW QUESTION");
      handleStart();
      setStatsForNextQuestion(data);
    });
    socket.on("restart", (data) => {
      console.log("GOT RESTART");
      setStatsForRestart(data);
    });

    socket.on("ping", () => {
      socket.emit("pong", { id, name: user.name });
    });

    socket.on("true", (data) => {
      setAnswerInfo({
        track_name: data.track_name,
        artist_name: data.artist_name,
        language_name: data.language_name,
        language_native_name: data.language_native_name,
        language_code: data.language_code,
      });
      if (songRef.current.questionCount === 15) {
        handleEndGame();
      } else {
        handleStop();
        setUser({ ...userRef.current, isAnswered: true });
      }
    });

    socket.on("false", (data) => {
      console.log("RECEIVED FALSE");
      setSecondsRemaining((prevState) => prevState + 10);
      secondsRef.current = secondsRef.current + 10;
      setAnimateClockEffect(true);
    });
    socket.on("disconnect", () => {
      console.log("GOT DISCONNECT");
      handleStop();
      setDisconnected(true);
    });

    socket.io.on("reconnect", () => {
      console.log("GOT RECONNECT");

      setDisconnected(false);
      handleStart();
      socket.emit("client-reconnect", {
        id,
        name: userRef.current.name,
        mode: "single",
      });
    });

    socket.on("saved-score", (data) => {
      console.log("NEWEST ADDITION OF LOGS," + data.message);
      const message = data.message;
      if (message !== "no-permission") {
        socket.emit("get-rank", {
          id,
          listName: "ClassicWithTimer",
          value: secondsRef.current,
          userName: userRef.current.name,
          userId: userId.current,
        });
      }
    });

    socket.on("rank", async (data) => {
      const rank = data.rank;
      const monthlyRank = data.monthlyRank;
      socket.emit("create-certificate", {
        id,
        listName: "ClassicWithTimer",
        value: secondsRef.current,
        userName: userRef.current.name,
        certificateId,
        alltime: data.rank,
        monthly: data.monthlyRank,
        verify: `https://langoguessr.com/certificate/${certificateId}`,
      });
      await Swal.fire({
        title: `<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="trophy" class="svg-inline--fa fa-trophy" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" style="width:120px;height:120px;color:#d7be36"><path fill="currentColor" d="M400 0H176c-26.5 0-48.1 21.8-47.1 48.2c.2 5.3 .4 10.6 .7 15.8H24C10.7 64 0 74.7 0 88c0 92.6 33.5 157 78.5 200.7c44.3 43.1 98.3 64.8 138.1 75.8c23.4 6.5 39.4 26 39.4 45.6c0 20.9-17 37.9-37.9 37.9H192c-17.7 0-32 14.3-32 32s14.3 32 32 32H384c17.7 0 32-14.3 32-32s-14.3-32-32-32H357.9C337 448 320 431 320 410.1c0-19.6 15.9-39.2 39.4-45.6c39.9-11 93.9-32.7 138.2-75.8C542.5 245 576 180.6 576 88c0-13.3-10.7-24-24-24H446.4c.3-5.2 .5-10.4 .7-15.8C448.1 21.8 426.5 0 400 0zM48.9 112h84.4c9.1 90.1 29.2 150.3 51.9 190.6c-24.9-11-50.8-26.5-73.2-48.3c-32-31.1-58-76-63-142.3zM464.1 254.3c-22.4 21.8-48.3 37.3-73.2 48.3c22.7-40.3 42.8-100.5 51.9-190.6h84.4c-5.1 66.3-31.1 111.2-63 142.3z"></path></svg><br><p style="margin-bottom:24px"><strong>Congratulations, ${userRef.current.name}!</strong></p>`,
        icon: "",
        html: `<div style="display:flex;flex-direction:column"><div style="display:flex;justify-content:center;margin-bottom:24px"><div style="text-align:center"><p><strong>AllTime Rank</strong></p><p>#${rank}</p></div><div style="margin-left:16px;margin-right:16px;width:2px;background-color:rgba(10,10,10,.3)"></div><div style="text-align:center"><p><strong>Monthly Rank</strong></p><p>#${monthlyRank}</p></div></div></div>`,
        showCancelButton: true,
        showCloseButton: true,
        showDenyButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        denyButtonText: "View Certificate!",
        confirmButtonText:
          '<svg style="color: #005f97" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="music" class="svg-inline--fa fa-music " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M499.1 6.3c8.1 6 12.9 15.6 12.9 25.7v72V368c0 44.2-43 80-96 80s-96-35.8-96-80s43-80 96-80c11.2 0 22 1.6 32 4.6V147L192 223.8V432c0 44.2-43 80-96 80s-96-35.8-96-80s43-80 96-80c11.2 0 22 1.6 32 4.6V200 128c0-14.1 9.3-26.6 22.8-30.7l320-96c9.7-2.9 20.2-1.1 28.3 5z"></path></svg>',
        cancelButtonText:
          '<div style="display:flex;column-gap:8px; align-items:center"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="rotate-left" class="svg-inline--fa fa-rotate-left " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M48.5 224H40c-13.3 0-24-10.7-24-24V72c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2L98.6 96.6c87.6-86.5 228.7-86.2 315.8 1c87.5 87.5 87.5 229.3 0 316.8s-229.3 87.5-316.8 0c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0c62.5 62.5 163.8 62.5 226.3 0s62.5-163.8 0-226.3c-62.2-62.2-162.7-62.5-225.3-1L185 183c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8H48.5z"></path></svg><p>Replay</p></div>',
        customClass: {
          actions: "w-[80%]",
          denyButton:
            "h-[40px] flex-[4] bg-[#7066e0] hover:bg-[#5347df] text-white py-2 px-4 rounded",
          cancelButton:
            "h-[40px] flex-[1] bg-orange-500 hover:bg-orange-600 text-white py-2 px-4 rounded",
          confirmButton:
            "flex justify-center items-center h-[40px] w-[40px] bg-[#00a1ff57] hover:bg-[#00a1ff73] text-white py-2 px-4 rounded",
        },

        focusConfirm: false,
        focusCancel: false,
        focusDeny: false,
        preConfirm: (response) => {
          window.open(songRef.current.link, "_blank");
          return false;
        },
        showClass: {
          popup: "animate__animated animate__fadeInDown animate__delay",
        },
      }).then((response) => {
        if (response.dismiss === "cancel") {
          socket.emit("replay", {
            id,
            name: userRef.current.name,
            mode: "single",
          });
        } else if (response.dismiss === "close") {
          console.log(response.dismiss);
          handleMenu();
        } else {
          console.log(response.dismiss);
          handleCertificate();
        }
      });
    });

    return () => {
      socket.removeAllListeners();
      Swal.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, handleEndGame]);

  useInterval(
    () => {
      if (secondsRemaining >= 0) {
        console.log("BEFORE INCREMENTING");
        setSecondsRemaining((prevState) => prevState + 1);
        secondsRef.current = secondsRef.current + 1;
      } else {
        setStatus(STATUS.STOPPED);
      }
    },
    status === STATUS.STARTED ? 1000 : null

    // passing null stops the interval
  );

  const userId = useRef("");
  const navigate = useNavigate();

  const handleSolved = useCallback(() => {
    handleStop();
    socket.emit("solved", { id, name: userRef.current.name, mode: "single" });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.name]);

  const handleReport = async () => {
    handleStop();
    const { value } = await Swal.fire({
      title: "Report Song",
      input: "select",
      inputOptions: {
        SONG: {
          noLyrics: "This song has too few or no lyrics at all.",
        },
        LANGUAGE: {
          wrongLanguage: "This song is not in this language!",
        },
        VIDEO: {
          wrongVideo: "This video does not belong to this song!",
          removedVideo: "This video is removed!",
          privateVideo: "This video is private!",
          regionBlocked: "This video is blocked in my region!",
          adultVideo: "This video has sexual content!",
        },
      },
      inputPlaceholder: "Report Song",
      showCancelButton: true,
      footer: "Please confirm before reporting! Thank you!",
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value !== "") {
            handleStart();
            resolve();
          } else {
            resolve("You need to select valid report option!");
          }
        });
      },
    });

    handleStart();
    if (value) {
      await axios.post("/api/report/", {
        track: { id: song.id, name: "" },
        reportType: value,
      });
    }
  };
  const handleMenu = () => {
    navigate(`/`);
  };

  const handleCertificate = () => {
    navigate(`/certificate/${certificateId}`);
  };

  useEffect(() => {
    userRef.current = user;
  }, [user]);

  useEffect(() => {
    songRef.current = song;
  }, [song]);

  useEffect(() => {
    if (user.name) {
      setTimeout(() => {
        socket.emit("joinroom", {
          name: userRef.current.name,
          id,
          mode: "single",
        });
        if (!videoPlaying) setVideoPlaying(true);
      }, 400);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.name]);

  useEffect(() => {
    let timeout;
    if (animateClockEffect) {
      timeout = setTimeout(() => setAnimateClockEffect(false), 375);
    }
    return () => clearTimeout(timeout);
  }, [animateClockEffect]);

  useEffect(() => {
    if (id) {
      return;
    }
    const newId = uuidv4();
    setId(newId);
    socket.emit("create-single-test", newId);
    const nameFunc = async () => {
      await Swal.fire({
        title: "Enter your nick name!",
        input: "text",
        inputLabel: "Name is required in order to join room!",
        inputValue: "",
        showCancelButton: false,
        allowOutsideClick: false,
        showCloseButton: true,
        allowEscapeKey: false,
        footer: "You can return to menu by dismissing this modal!",
        inputValidator: (value) => {
          if (!value) {
            return "You need to write something!";
          } else if (value.length > 16) {
            return "Max allowed name length is 16!";
          }
        },
      }).then((result) => {
        if (result.isDismissed) {
          handleMenu();
        } else {
          setUser({ ...userRef.current, name: result.value });
        }
      });
    };

    const tempId = short.generate();
    userId.current = "#" + tempId.slice(0, 8);
    nameFunc().catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAnswerClick = (clickedAnswer) => {
    setUser({ ...userRef.current, latestAnswer: clickedAnswer });
    console.log("in effect");
    const pickedLang = clickedAnswer;
    socket.emit("picked-lang", {
      answer: pickedLang,
      name: userRef.current.name,
      id,
      mode: "single",
    });
    console.log(pickedLang);
  };

  const handleEndGame = useCallback(async () => {
    setEnd(true);
    handleStop();
    socket.emit("save-score", {
      listName: "ClassicWithTimer",
      id,
      value: secondsRef.current,
      userName: userRef.current.name,
      userId: userId.current,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const Modal = () => {
    Swal.fire({
      title: song.name,
      html: `<p class="text-green-800 text-2xl font-semibold">Correct Answer<p/>${renderToString(
        <SongDetailCard
          answerInfo={answerInfo}
          variant={"success"}
          externalLink={songRef.current.link}
        />
      )}`,
      footer: `${answerInfo.language_name} (${answerInfo.language_native_name})`,
      confirmButtonText: "Next Question!",
      icon: "success",
      allowOutsideClick: false,
      allowEscapeKey: false,

      customClass: {
        actions: "my-actions",
        confirmButton: "order-1 right-gap",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("girdi");
        handleSolved();
      }
    });
  };

  return (
    <div class="flex flex-col bg-gradient-to-r md:min-h-[calc(100vh_-_56px)] min-h-[calc(100vh_-_120px)] items-center">
      <div class="mb-2 md:mb-5 mx-3">
        <p class="font-medium text-xl">
          {user.name}
          <span className="text-xs"> {userId.current}</span>
        </p>
      </div>

      <div className="flex flex-col items-center justify-center w-2/3 xl:w-1/2">
        <div class="w-full flex space-x-2 items-center justify-between my-4">
          <p
            class={`font-medium text-2xl align-middle justify-center transition-all ease-out duration-500 lg:text-3xl
          ${animateClockEffect && "animate-text"}`}
          >
            {twoDigits(minutesToDisplay)}:{twoDigits(secondsToDisplay)}
          </p>

          <p class="flex space-x-2 justify-center font-medium text-3xl lg:text-5xl ">
            {song.questionCount}/15
          </p>
        </div>

        {user.isAnswered && <Modal />}

        <div
          class={`h-48 md:h-96 xl:h-[calc(50vh)] flex justify-center relative ${
            videoPlayerMode === "audio" ? "aspect-square" : "w-full"
          } `}
        >
          <ReactPlayer
            id="player"
            class="player absolute mt-0 ml-0"
            onPlay={() => setVideoPlaying(true)}
            onPause={() => setVideoPlaying(false)}
            fluid={false}
            loop={true}
            controls={true}
            playing={videoPlaying}
            height={"100%"}
            width={"100%"}
            url={song.link}
            config={{
              youtube: {
                playerVars: {
                  fs: 0,
                  modestbranding: 1,
                  origin: "https://langoguessr.com",
                  cc_load_policy: 0,
                },
              },
            }}
          />
          <div
            className={`flex ${
              videoPlayerMode === "audio" ? "h-[calc(100%_-_65px)]" : "h-[64px]"
            }   rounded-b-xl w-full items-center justify-center transition duration-300 text-white absolute top-0`}
          >
            <div
              onClick={() => {
                if (videoPlayerMode === "audio") {
                  setVideoPlaying((prev) => !prev);
                }
              }}
              className={`h-full flex-shrink-0 flex-[4] backdrop-blur-2xl ${
                videoPlayerMode === "audio" && "cursor-pointer"
              }`}
            />
            <div
              onClick={handleReport}
              className=" cursor-pointer absolute rounded-b-xl top-0 py-2 px-8 bg-red-800 hover:bg-red-600"
            >
              <FontAwesomeIcon icon={faWarning} /> Report
            </div>
          </div>
        </div>
      </div>

      <div class="flex items-center px-4 gap-2 justify-center mt-8 flex-wrap">
        {song.choices.map((element, idx) => (
          <AnswerButton
            key={`answer-${idx}`}
            answer={element}
            onClick={() => handleAnswerClick(element.name)}
            disabled={
              user.latestAnswer === element.name || end || disconnected
                ? true
                : false
            }
            type={
              user.latestAnswer === "" ||
              (user.latestAnswer !== element.name && element.name !== "")
                ? "neutral"
                : user.latestAnswer === element.name && user.isAnswered
                ? "correct"
                : "incorrect"
            }
          >
            <p>{element === "" ? "getting lang" : element.name}</p>
          </AnswerButton>
        ))}
      </div>
    </div>
  );
}
function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}
const twoDigits = (num) => String(num).padStart(2, "0");
