import { faInfinity } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import React from "react";
import { useNavigate } from "react-router";
import "tw-elements";
import OptionCard from "../components/OptionCard";
import useAnalytics from "../utils/useAnalytics";

export default function SoloModeSelectionScreen() {
  const navigate = useNavigate();
  useAnalytics()

  return (
    <div className="flex justify-center items-center md:min-h-[calc(100vh_-_56px)] min-h-[calc(100vh_-_120px)] flex-wrap">
      <OptionCard
        title="Classic"
        subtitle="Classic LangoGuessr experience with 15 questions!"
        iconAlternativeText="15"
        onClick={() => {
          navigate("classic");
        }}
        variant="blue"
        description="Score for finding varies on the wrong amount you have on that question. 0:20, 1:15, 2:5, 3:1"
        buttonText="Play"
      />
      <OptionCard
        title="Classic With Timer"
        subtitle="Classic LangoGuessr experience against the clock!"
        iconAlternativeText={"15"}
        cornerIcon={faClock}
        onClick={() => {
          navigate("classic-with-timer");
        }}
        variant="blue"
        description="Score is the timer. Each wrong prediction adds 10 seconds to the timer."
        buttonText="Play"
      />
      <OptionCard
        title="Infinite"
        subtitle="How many languages you will predict with 5 HP!"
        icon={faInfinity}
        onClick={() => {
          navigate("infinite");
        }}
        variant="red"
        description="Score for finding the answer is evaluated on the formula of score + Player's Hp for every question."
        buttonText="Play"
      />
      <OptionCard
        title="Infinite With Timer"
        subtitle="How many languages you will predict within 5 minutes!"
        onClick={() => {
          navigate("infinite-with-timer");
        }}
        icon={faInfinity}
        cornerIcon={faClock}
        variant="red"
        description="Each wrong prediction removes 10 seconds from the clock"
        buttonText="Play"
      />
    </div>
  );
}
